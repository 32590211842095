import React from 'react'
import car from "../../../../Assets/Images/car.svg";
import tick from "../../../../Assets/Images/success.svg";
import Botton from '../../../Button/Button';

const YetToStart = (props) => {

    const { FormValue, ClinetPolicyActiveData, handleInputChange, bike, handleDownloadPolicy, car } = props


    const handleUpdateKyc = () => {
        alert("Update KYC Attempted");
    }

    return (
        <div className="ProfileCard-container">
            {ClinetPolicyActiveData.length > 0 ? ClinetPolicyActiveData?.map((data, key) => {
                let IDvvaluesandCover = JSON.parse(data['policy_breakup'])
                let SDate = data?.policy_start_date?.split("T")
                let EDate = data?.policy_end_date?.split("T")

                const getDisplayName = (displayName) => {
                    const displayNameMap = {
                        "Personal accident cover": "PA Cover",
                        "Personal Accident Cover": "PA Cover",
                        "Engine protect": "Engine Protection",
                        "Return to invoice cover": "Invoice Cover",
                        "Consumables premium": "Consumables",
                        "Paid driver cover": "Driver Cover",
                        "Extra car protect": "24/7 Road Assistance",
                        "Zero depreciation premium": "Zero Depreciation",
                        "Zero Depreciation Cover": "Zero Depreciation",

                    };

                    return displayNameMap[displayName] || displayName;
                }

                return (
                    <section className="NoPoliciesFound-container PoliciesFound-container ">
                        <div className="product-info-container">
                            <div className="product-info-left-container">
                                <div className="product-icon-container">
                                    <img src={data.insured_product_type == "Private_Car" ? car : bike} alt="" srcSet="" />
                                </div>
                                <div className="product-insurer-container">
                                    <p>
                                        {data.policy_number ? data.policy_number + " - " : ""} {(data.insured_product_type?.toUpperCase()).replace("_", " ")}
                                    </p>
                                    <p>{data.insurer_name?.toUpperCase()}</p>
                                </div>
                            </div>

                            {data?.policy_pdf_url &&
                                <div className="product-info-right-container">
                                    <p>
                                        <span>Start:</span> {SDate[0]}
                                    </p>
                                    <p>
                                        <span>End:</span> {EDate[0]}
                                    </p>
                                </div>
                            }
                        </div>

                        <div className="seprator-policyCard"></div>

                        <div className="vehicleInfo-policyCard ">
                            <p className="textWithKey">
                                <span>Name: </span>
                                {data?.policy_holder_name.toUpperCase()}
                            </p>
                            <p className="textWithKey">
                                <span>Car Number: </span> {data?.reg_no ?? "NEW"}
                            </p>
                            {/* <p className="textWithKey">
                                <span>IDV Value: </span> {IDvvalues?.values[0].idv_amount}
                            </p> */}
                        </div>

                        <div className="breakup-contianer-policyCard">

                            <div className="breakup-contianer-part-policyCard">

                                {
                                    IDvvaluesandCover?.addons?.map((e, key) => {
                                        if (key <= 2) {

                                            return (
                                                <div className="breakup-contianer-part-left-policyCard">
                                                    <div className="txt-with-tick-policycard">
                                                        <p className="textWithKey textWithKey-bold">
                                                            <span>{getDisplayName(e.display_name)}</span> {e?.price?.net_premium}
                                                        </p>
                                                        <img src={tick} alt="" srcSet="" />
                                                    </div>
                                                </div>
                                            )
                                        }


                                    })

                                }

                            </div>
                            <div className="breakup-contianer-part-policyCard">
                                <div className="breakup-contianer-part-left-policyCard">
                                    {IDvvaluesandCover?.addons?.map((data, key) => key > 2 && key <= 5 ? (
                                        <div key={key} className="txt-with-tick-policycard">
                                            <p className="textWithKey textWithKey-bold">
                                                <span>{getDisplayName(data.display_name)}</span> {data?.price?.net_premium}
                                            </p>
                                            <img src={tick} alt="" srcSet="" />
                                        </div>
                                    ) : (
                                        ""
                                    )
                                    )}
                                </div>
                            </div>

                            <div className="breakup-contianer-part-policyCard">
                                {IDvvaluesandCover?.addons?.map((data) => { })}
                                <div className="breakup-contianer-part-left-policyCard">
                                    {IDvvaluesandCover?.addons?.map((data, key) => {
                                        if (key > 5) {
                                            return (
                                                <div className="txt-with-tick-policycard" >
                                                    <p className="textWithKey textWithKey-bold">
                                                        <span>{getDisplayName(data.display_name)}</span> {data?.price?.net_premium}
                                                    </p>
                                                    <img src={tick} alt="" srcSet="" />
                                                </div>
                                            )
                                        }


                                    })}

                                </div>
                            </div>
                        </div>

                        <div className="bottom-tabs-option-policyCard-container">
                            {data?.inspection_url &&

                                <div className="bottom-tabs-option-policyCard">
                                    <a
                                        href={data?.inspection_url}
                                        target="_blank"
                                    >
                                        <div
                                            className="tabs-option-policyCard"
                                        >
                                            <p>Inspection URL</p>
                                        </div>
                                    </a>
                                </div>
                            }

                            {data?.policy_pdf_url ?
                                <div className="bottom-tabs-option-policyCard" >
                                    <a
                                        href={data?.policy_pdf_url}
                                        download="fileName555555555.pdf"
                                        target="_blank"
                                    >
                                        <div
                                            className="tabs-option-policyCard"
                                            onClick={(e) => handleDownloadPolicy(data.policy_pdf_url)}
                                        >
                                            <p>Download Policy</p>
                                        </div>
                                    </a>



                                </div>
                                :
                                <div className="bottom-tabs-option-policyCard">
                                    <a
                                        href={data?.kyc_url}
                                        target="_blank"
                                    >
                                        <div
                                            className="tabs-option-policyCard"
                                        // onClick={(e) => handleDownloadPolicy(data.kyc_url)}
                                        >
                                            <p>Kyc Link</p>
                                        </div>
                                    </a>
                                </div>
                            }


                        </div>
                    </section>
                )


            }) : <h4> No Future Policies Found</h4>}

        </div>
    )
}

export default YetToStart
