import {
  shareLinkAction,
  phonevalidationAction
} from "../Actions/shareLinkAction";
import { createSlice } from "@reduxjs/toolkit";

export const shareLinkReducer = createSlice({
  name: "shareLink",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(shareLinkAction.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(shareLinkAction.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(shareLinkAction.rejected, (state, action) => {
      state.isError = true;
      state.isLoading = false;
    });
  },
  reducers: {
    resetashareLink: (state, action) => {
      state.data = undefined;
    },
  },
});
export const phonevalidationReducer = createSlice({
  name: "phonevalidation",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(phonevalidationAction.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(phonevalidationAction.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(phonevalidationAction.rejected, (state, action) => {
      state.isError = true;
      state.isLoading = false;
    });
  },
  reducers: {
    resetaphonevalidation: (state, action) => {
      state.data = undefined;
    },
  },
});
export const { resetashareLink } = shareLinkReducer.actions;
export const { resetaphonevalidation } = phonevalidationReducer.actions;
