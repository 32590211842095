import React, { useState, useEffect } from "react";
import { useRef } from "react";
import "./QuotesContainer.css";
import Botton from "../Button/Button";
import Plancard from "./Plancard";
import Modal from "react-modal";
import ConfirmDetails from "./ConfirmDetails.jsx";
import AOS from "aos";
import "aos/dist/aos.css";
import cross from "../../Assets/Images/Close.svg";
import caution from "../../Assets/Images/cautionImg.svg"
import ackosmartsaverCar from "../../Assets/Images/ackoSmartSaverCar.png"
import { transactionURLAction } from "../../redux/Actions/TransactionAction";
import { sbiQuickQuoteAction } from "../../redux/Actions/sbiApiAction";
import { shreeRamQuickQuoteAction } from "../../redux/Actions/shreeRamApiAction";
import { digitQuickQuoteAction } from "../../redux/Actions/digitApiAction";
import { alladdOnDataDataAction } from "../../redux/Reducers/addOnsDataReducer";
import { carDetailsCalculateTotalPremium } from "../../utils/commonUtils.js";
import { nationalQuickQuoteAction } from "../../redux/Actions/nationalApiAction.js";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  zunoQuickQuoteAction,
} from "../../redux/Actions/zunoApiAction";
import {
  iciciQuickQuoteAction,
} from "../../redux/Actions/iciciApiAction";
import {
  tataQuickQuoteAction,
} from "../../redux/Actions/tataApiAction";
import { getCompanyName } from "../../redux/Reducers/companyNameReducer.js";
import { ackoQuickQuoteAction } from "../../redux/Actions/ackoApiAction.js";
import { hdfcQuickQuoteAction } from "../../redux/Actions/hdfcApiAction.js";
import InputFields from "../InputFields/InputFields";
import loader2 from "../../Assets/Loader/Loader.gif";
import Close from "../../Assets/Images/Close.svg";
import { unitedQuickQuoteAction } from "../../redux/Actions/unitedApiAction.js";
import ButtonLoader from "../Pages/Loader/Button Loader/ButtonLoader.jsx";
import RadioButton from "../RadioButton/RadioButton.jsx";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    height: "auto",
    transform: "translate(-50%, -50%)",
  },
};

const customStylesForMobile = {
  content: {
    top: "auto",
    left: "0",
    right: "0",
    bottom: "0",
    margin: "0",
    transform: "none",
    borderRadius: "10px 10px 0 0",
    padding: "20px",
  },
};

Modal.setAppElement("#root");

const QuotesContainer = (props) => {

  const {
    PolicyPlanType,
    carDetails,
    handleInputCarDetails,
    businessType,
    vehicleData,
    ShowPcSumInsuredField,
    ShowAccesoriesField,
    isFindMyCar,
    setShowBottomTab,
    policyTypeOption,
    passVehicleData,
    policyTypeList,
    setLoaderFromConfirmPg,
    CompanyWiseLoader,
    setCarDetails,
    ShowExpDateCalendar,
    setShowExpDateCalendar,
    ShowRegDateCalendar,
    setShowRegDateCalendar,
    ShowOdExpDateCalendar,
    setShowOdExpDateCalendar,
    ShowSkeleton,
    shreeRamQuickQuoteReducerWithAddon,
    test,
    setDigitAddon,
    DigitAddon,
    objWihtAddOn,
    carDetailsCopyState,
    setcarDetailsCopyState,
    setIsRegDateActive,
    CalendarDeactiveFlag,
    ShowPackageModal,
    setShowPackageModal,
    ShowDigPackageModal,
    setShowDigPackageModal,
    setShowICICIPackageModal,
    ShowICICIPackageModal,
    setShowNilDepModal,
    setIsActiveFromPlanCard,
    DigitPlan,
    ICICIPlan,
    digitAddonsplan,
    setShowRtiModal,
    stopApi,
    allIDVValues,
    formattedRegDate,
    formattedODexpDate,
    handleCopiedDetails,
    upDateCarDetailsConfirm,
    formattedpolicyExpDate,
    setackosmartZerodepOpen,
    ackosmartZerodepOpen,
    setTest,
    breakinForUnited,
  } = props;

  const [modalIsOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");

  let bodyData = useSelector((state) => {
    return state.bodyData;
  });

  const CountVal = useRef(0)

  const [allCarDetails, setallCarDetails] = useState({});
  const [logo, setlogo] = useState("");
  const [CompanyName, setCompanyName] = useState(null);
  const [VerifyTabActive, setVerifyTabActive] = useState(false);
  const [showDeclaration, setshowDeclaration] = useState(true);
  const [showRegNoErr, setshowRegNoErr] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [showCngErr, setShowCngErr] = useState(null);
  const [CheckboxList, setCheckboxList] = useState({
    declaration: true,
  });

  useEffect(() => {
    AOS.init({
      duration: 200,
    });
    AOS.refresh();
  }, []);

  const getSbiQuickQuoteData = useSelector((state) => {
    return state?.sbiQuickQuote;
  });

  const shreeRamQuote = useSelector((state) => {
    return state?.shreeRamQuickQuote;
  });

  const digitQuote = useSelector((state) => {
    return state?.digitQuickQuote;
  });

  const iciciQuote = useSelector((state) => {
    return state?.iciciQuickQuote;
  });

  const hdfcQuote = useSelector((state) => {
    return state?.hdfcQuickQuote;
  });

  const ackoQuote = useSelector((state) => {
    return state?.ackoQuickQuote;
  });

  // ZUNO
  const zunoQQuote = useSelector((state) => {
    return state?.zunoQuickQuote;
  });

  // TATA
  const tataQQuote = useSelector((state) => {
    return state?.tataQuickQuote;
  });

  // UNITED
  const unitedQQuote = useSelector((state) => {
    return state?.unitedQuickQuote;
  });
  // National 
  const natioalQuote = useSelector((state) => {
    return state?.nationalQuickQuote
  })
  let PlansData = useSelector((state) => {
    return state?.digitQuickQuote12;

  })

  let quoteData = {};

  if (CompanyName == "sbi") {
    // companyImg = sbi
    quoteData = getSbiQuickQuoteData;
  } else if (CompanyName == "shreeram") {
    // companyImg = shreeRam
    quoteData = shreeRamQuote;
  } else if (CompanyName == "digit") {
    // companyImg = digit
    quoteData = digitQuote;
  } else if (CompanyName == "icici") {
    // companyImg = icici
    quoteData = iciciQuote
  } else if (CompanyName == "hdfc") {
    // companyImg = hdfc
    quoteData = hdfcQuote
  } else if (CompanyName == "acko") {
    // companyImg = acko
    quoteData = ackoQuote
  } else if (CompanyName == "zuno") {
    // companyImg = zuno
    quoteData = zunoQQuote
  } else if (CompanyName == "tata") {
    // companyImg = tata
    quoteData = tataQQuote?.data?.data?.quote
  } else if (CompanyName == "united") {
    // companyImg = united
    quoteData = unitedQQuote
  } else if (CompanyName == "national") {
    quoteData = natioalQuote
  }

  const [ShowRegNumModal, setShowRegNumModal] = useState(false);

  const month = new Date(carDetails.policyRegDate).toLocaleString("default", {
    month: "long",
  });

  const year = new Date(carDetails.policyRegDate).toLocaleString("default", {
    year: "numeric",
  });

  useEffect(() => {
    if (VerifyTabActive || loading1 || ShowRegNumModal || modalIsOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
    return () => {
      document.body.style.overflow = "";
    };
  }, [VerifyTabActive, loading1, ShowRegNumModal, modalIsOpen]);

  function openModal(logo, carDetails, companyName) {
    setIsOpen(true);
    setallCarDetails(carDetails);
    setlogo(logo);
    setCompanyName(companyName);
    setShowBottomTab(false);
    setVerifyTabActive(false)
    if (window.innerWidth <= 400) {
      console.log("kaho");
      let zoomLevel;
      zoomLevel = 1;
      document.body.style.zoom = zoomLevel?.toString();
    }
  }

  function closeModal() {
    setcarDetailsCopyState((...prev) => ({
      ...prev,
      vehicleNumberCopy: carDetails?.vehicleNumber?.toUpperCase() || "",
      makeCopy: carDetails?.make || "",
      modelCopy: carDetails?.model || "",
      varientCopy: carDetails?.varient || "",
      fuelTypeCopy: carDetails?.fuelType || "",
      ccCopy: carDetails?.cc || "",
      regDateCopy: carDetails?.policyRegDate || "",
      prevPolicyTypeCopy: carDetails?.prevPolicyType || "",
      policyExpCopy: carDetails?.policyExpDate || "",
      odPolicyExpCopy: carDetails?.prevPolicyODexpDate || "",
      tpPolicyExpCopy: carDetails?.prevPolicyTPexpDate || "",
      isClaimPrevPolicyCopy: carDetails?.isClaimPrevPolicy,
      prevNcbCopy: carDetails?.prevNcb,
      newNcbCopy: carDetails?.newNcb,
      electricalAccesoriesCopy: carDetails?.electricalAccesories || "",
      nonElectricalAccesoriesCopy: carDetails?.nonElectricalAccesories || "",
      policyTypeValueCopy: carDetails?.PolicyTypeValue || "",
      idvValueCopy: carDetails?.idvValue || "",
      quotePackageNameCopy: carDetails?.quotePackageName || "",
      quotePackageNameDigitCopy: carDetails?.quotePackageNameDigit || "",
      quotePackageNameICICICopy: carDetails?.quotePackageNameICICI || "",
      cngKitValuecopy: carDetails?.cngKitValue || "",
      isCngLpgVehicleTypecopy: carDetails?.isCngLpgVehicleType || false,
    }))

    setIsOpen(false);
    setShowBottomTab(true);
    let zoomLevel;
    if (window.innerWidth <= 400) {
      zoomLevel = 0.9;
      document.body.style.zoom = zoomLevel?.toString();
    }
  }

  const triggerVerifyTab = (companyImg, carDetails, companyName) => {
    dispatch(
      getCompanyName({
        companyName: companyName,
      })
    );
    setlogo(companyImg);
    setCompanyName(companyName);
    setallCarDetails(carDetails);

    if (carDetails?.vehicleNumber.length < 8 && carDetails?.typeOfBusiness !== "New") {
      setShowRegNumModal(true);
      setshowRegNoErr(true);
    } else {
      setVerifyTabActive(true);
      setShowRegNumModal(false);
      setshowRegNoErr(false);
    }
    // setVerifyTabActive(true);
  }

  let ackoQuickQuoteData = useSelector((state) => {
    return state.ackoQuickQuote
  })



  useEffect(() => {
    if (modalIsOpen) {
      setLoaderFromConfirmPg(true);
    } else {
      setLoaderFromConfirmPg(false);
    }
    if (modalIsOpen) {
      document.body.style.overflow = 'hidden';
      if (window.innerWidth <= 400) {
        console.log("kaho");
        let zoomLevel1;
        zoomLevel1 = 1;
        document.body.style.zoom = zoomLevel1?.toString();
      }
    } else {
      document.body.style.overflow = '';
      if (window.innerWidth <= 400) {
        console.log("kaho");
        let zoomLevel;
        zoomLevel = .9;
        document.body.style.zoom = zoomLevel?.toString();
      }
    }

    return () => {
      document.body.style.overflow = '';
    };

  }, [modalIsOpen])

  const verifyDetailsContent = [
    {
      id: 1,
      heading: "Vehicle Registration Number",
      value: carDetails?.vehicleNumber,
      headingTwo: "",
      valueTwo: "",
    },
    {
      id: 2,
      heading: businessType === "New" ? "Policy Start Date" : "Registration Date",
      value: formattedRegDate,
      headingTwo: "Manufacturing Date",
      valueTwo: month + " " + year,
    },
    {
      id: 3,
      heading: "Previous Policy Type",
      value: carDetails?.prevPolicyType,
      headingTwo: "Previous Policy Expiry Date",
      valueTwo: businessType !== "New" ? (carDetails?.prevPolicyType === 'Bundled' ? formattedODexpDate : formattedpolicyExpDate) : "",
    },
    {
      id: 4,
      heading: "Claim in previous policy",
      value: carDetails?.isClaimPrevPolicy == true ? "Yes" : "No",
      headingTwo: "Previous No claim bonus",
      valueTwo: carDetails?.isClaimPrevPolicy == true ? "N/A" : carDetails?.prevNcb + "%",
    },
  ]

  const filteredContent = businessType === "New"
    ? verifyDetailsContent.filter(item => item.id === 1 || item.id === 2)
    : verifyDetailsContent;

  const handleRoutePform = async (e) => {

    const previousValue = localStorage.getItem('currentValue');
    localStorage.setItem('previousValue', previousValue);
    localStorage.setItem('currentValue', CompanyName);
    let flag = true;
    if(carDetails?.typeOfBusiness?.toUpperCase() !== "NEW"){
      setCarDetails((prev) => ({
        ...prev,
        prevPolicyType: carDetailsCopyState?.prevPolicyTypeCopy,
        vehicleNumber: carDetailsCopyState?.vehicleNumberCopy,
        policyExpDate: carDetailsCopyState?.policyExpCopy,
        prevPolicyODexpDate: carDetailsCopyState?.odPolicyExpCopy,
        prevPolicyTPexpDate: carDetailsCopyState?.tpPolicyExpCopy,
        isClaimPrevPolicy: carDetailsCopyState?.isClaimPrevPolicyCopy,
        prevNcb: carDetailsCopyState?.isClaimPrevPolicyCopy === true ? "0" : carDetailsCopyState?.prevNcbCopy,
        newNcb: carDetailsCopyState?.isClaimPrevPolicyCopy === true ? "0" : carDetailsCopyState?.newNcbCopy,
        policyRegDate: carDetailsCopyState?.regDateCopy,
        mfgDate: carDetailsCopyState?.mfgDatecopy,
        PolicyTypeValue: carDetailsCopyState?.policyTypeValueCopy,
      }))
    }else{
      setCarDetails((prev) => ({
        ...prev,
        PolicyTypeValue: carDetailsCopyState?.policyTypeValueCopy,
        policyRegDate: carDetailsCopyState?.regDateCopy,
        mfgDate: carDetailsCopyState?.mfgDatecopy,
      }))
    }
    if (carDetails) {
     
      await dispatch(alladdOnDataDataAction(carDetails));
    }
    let regnumber = carDetailsCopyState?.vehicleNumberCopy;
    if (regnumber.length < 8) {
      flag = false;
    }
    if (carDetails?.typeOfBusiness?.toUpperCase() !== "NEW") {
      if (flag && showDeclaration && !showCngErr && CheckboxList?.declaration) {


        let data = {
          uuid: id,
          companyName: CompanyName,
          type: "update_company_name",
        };
        setLoading1(true);
        await dispatch(transactionURLAction(data));
        let data2 = {
          ...bodyData.data,
          vehicleNumber: carDetailsCopyState?.vehicleNumberCopy,
          ...{ coverage: [test] },
        }

        if (CompanyName !== "acko" && carDetailsCopyState?.prevPolicyTypeCopy === "Third Party" && carDetails?.PolicyTypeValue !== "Third Party") {
          closeModal();
          setLoading1(false)
        } else {
          let zoomLevel;
          if (window.innerWidth <= 400) {
            zoomLevel = 0.9;
            document.body.style.zoom = zoomLevel?.toString();
          }
          setTimeout(() => {
            navigate("/Propsal-Form?id=" + id, {
              state: {
                allCarDetails: JSON.parse(JSON.stringify(allCarDetails)), // ensure serializable
                logo: logo, // assuming it's a URL or a serializable object
                CompanyName: CompanyName,
              },
            });
            setLoading1(false);
          }, 1500)
         
        }
      } else if (!flag) {
        var divElement = document.getElementById("confirmContainer");
        if (divElement) {
          divElement.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }
        setshowRegNoErr(true);
        setLoading1(false);
      }
    } else {
      if (CheckboxList?.declaration) {
        setLoading1(true);
        let data = {
          uuid: id,
          companyName: CompanyName,
          type: "update_company_name",
        };
        setLoading1(true)
        await dispatch(transactionURLAction(data));
        let data2 = {
          ...bodyData.data,
          vehicleNumber: carDetailsCopyState?.vehicleNumberCopy,
          ...{ coverage: [test] },
        }

        let zoomLevel;
        if (window.innerWidth <= 400) {
          zoomLevel = 0.9;
          document.body.style.zoom = zoomLevel?.toString();
        }
        setTimeout(() => {
          navigate("/Propsal-Form?id=" + id, {
            state: {
              allCarDetails: JSON.parse(JSON.stringify(allCarDetails)), // ensure serializable
              logo: logo, // assuming it's a URL or a serializable object
              CompanyName: CompanyName,
            },
          });
          setLoading1(false);
        }, 1500)

      }
     
    }
  };

  const CloseSmartzeroDep = () => {
    setackosmartZerodepOpen(false)
    setTest((prevCheckboxes) =>
      prevCheckboxes.map((checkbox) =>
        checkbox.id === "checkbox17" ? { ...checkbox, checked: false } : checkbox
      )
    );

  }
  const CloseSmartzeroDepWithSmartSaver = (() => {
    setackosmartZerodepOpen(false)
  })
  const ZeroDepSmartmodla = (() => {
    setackosmartZerodepOpen(true)
  })

  return (
    <>

      <Modal
        isOpen={ackosmartZerodepOpen}
        onRequestClose={CloseSmartzeroDep}
        style={window.innerWidth <= 610 ? customStylesForMobile : customStyles}
      >
        <div className="acko_SmartSaverContainer">
          <div className="acko_smart_saver_carPicContainer">
            <div style={{ display: "flex", justifyContent: "end", cursor: "pointer", }} onClick={CloseSmartzeroDep}>
              <img src={Close} alt="" style={{ padding: "1rem" }} />

            </div>
            <div className="SmartDepHeading">
              <p>Zero Depreciation Smart Saver</p>
              <div style={{ paddingBottom: "-5px" }}>
                <img src={ackosmartsaverCar} alt="" />
              </div>
            </div>
          </div>
          <div className="smartZeroDepAcko">
            <div className="smartZeroDepAckoflex" >

              <div className="zerodep_safeDrive">
                <span className="exclusiveOffer">
                  <p>Exclusive Offer</p>
                </span>
                <p>Zero Depreciation:</p>
                <div className="safe_Drive">
                  <p>Safe Driver</p>
                </div>
                <div className="acko_dep_content">
                  <p>
                    This plan has been designed for careful drivers like you who rarely claim. It offers Zero Depreciation coverage at low price.<br /><br />
                    Most safe drivers never claim. In fact, 4 out of 5 people who choose this plan enjoy extra savings on their car insurance every year.
                  </p>
                  <div className="what_happens_acko">
                    <div>What happens If I Claim?</div>
                    <p> In case of a claim  ₹ 5,000 will be deducted from your claim amount</p>
                  </div>
                </div>
              </div>
              <div className="SmartDepBtn" onClick={CloseSmartzeroDepWithSmartSaver}>
                <p>OK, Got it</p>
              </div>
            </div>

          </div>
        </div>

      </Modal>

      {/* <div id="cover-spin" style={{ display: loading1 ? "" : "none" }}>
        <img src={loader2} className="gifLoader"></img>
      </div> */}

      <div className="section_bottomContent_vp">
        <div>
          <Plancard
            openModal={openModal}
            closeModal={closeModal}
            planType={carDetails?.PolicyTypeValue}
            carDetails={carDetails}
            logo={logo}
            handleInputCarDetails={handleInputCarDetails}
            setShowBottomTab={setShowBottomTab}
            ShowSkeleton={ShowSkeleton}
            test={test}
            setShowOdExpDateCalendar={setShowOdExpDateCalendar}
            setIsRegDateActive={setIsRegDateActive}
            setDigitAddon={setDigitAddon}
            DigitAddon={DigitAddon}
            objWihtAddOn={objWihtAddOn}
            CalendarDeactiveFlag={CalendarDeactiveFlag}
            setcarDetailsCopyState={setcarDetailsCopyState}
            carDetailsCopyState={carDetailsCopyState}
            setCarDetails={setCarDetails}
            ShowPackageModal={ShowPackageModal}
            setShowPackageModal={setShowPackageModal}
            setShowNilDepModal={setShowNilDepModal}
            setIsActiveFromPlanCard={setIsActiveFromPlanCard}
            triggerVerifyTab={triggerVerifyTab}
            ShowDigPackageModal={ShowDigPackageModal}
            setShowDigPackageModal={setShowDigPackageModal}
            setShowICICIPackageModal={setShowICICIPackageModal}
            ShowICICIPackageModal={ShowICICIPackageModal}
            DigitPlan={DigitPlan}
            ICICIPlan={ICICIPlan}
            digitAddonsplan={digitAddonsplan}
            allIDVValues={allIDVValues}
            loading1={loading1}
            ZeroDepSmartmodla={ZeroDepSmartmodla}
            breakinForUnited={breakinForUnited}
          />
        </div>
      </div>

      {VerifyTabActive && (
        <div
          className="edit-tabs-container-mob-height"
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
        >
          {VerifyTabActive && (
            <div className="active-bottom-edit-IDV-tabs">
              <div className="edit-tabs-container">
                <div className="edit-tabs-container-head edit-tabs-container-head-bg">
                  <p>Confirm your Policy Details</p>
                  <p>Ensure Premium Accuracy</p>
                  <img src={cross} onClick={() => setVerifyTabActive(false)} />
                </div>

                {
                  filteredContent?.map((data, key) => (
                    <div key={key} className="verify-tab-cd">
                      <div className="verify-tab-cd-child">
                        <p> {data.heading} </p>
                        <p> {data.value} </p>
                      </div>
                      <div className="verify-tab-cd-child">
                        <p> {data.headingTwo} </p>
                        <p> {data.valueTwo} </p>
                      </div>
                    </div>
                  ))
                }

                <div className="btn-vd-container">
                  {loading1 ? <ButtonLoader /> : <Botton className="btn-verifyDetails-p btn-vd" text="Proceed & Buy" event={(e) => handleRoutePform(e)} />}
                  <Botton className="btn-verifyDetails-s btn-vd" text="Edit Details" event={() => openModal(logo, carDetails, CompanyName)} />
                </div>

              </div>
            </div>
          )}
        </div>
      )}

      {ShowRegNumModal && (
        <div
          className="edit-tabs-container-mob-height"
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
        >
          <div className="active-bottom-edit-IDV-tabs" style={{ height: "20rem" }} >
            <div className="edit-tabs-container">
              <div className="edit-tabs-container-head edit-tabs-container-head-bg">
                <p>Confirm your Vehicle Number</p>
                <p>Ensure Premium Accuracy</p>
                <img src={cross} onClick={() => setShowRegNumModal(false)} />
              </div>

              <p style={{ fontWeight: "500", marginBottom: ".5rem" }} >Vehicle Number</p>

              <InputFields
                type="text"
                className="inputs-form uppercaseText"
                onChangeEvent={handleInputCarDetails}
                showRegNoErr={showRegNoErr}
                value={carDetails?.vehicleNumber.trim("")}
                name="vehicleNumber"
                id="vehicleNumber"
              />

              <div className="btn-vd-container btn-regNo-container">
                <Botton
                  className="btn-verifyDetails-p btn-vd"
                  text="Submit"
                  event={(e) => triggerVerifyTab(logo, carDetails, CompanyName)}
                />
              </div>

            </div>
          </div>
        </div>
      )}

      {
        !CompanyWiseLoader && (
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
            id=""
          >
            <ConfirmDetails
              type="submit"
              closeModal={closeModal}
              vehicleData={vehicleData}
              planType={carDetails?.PolicyTypeValue}
              carDetails={carDetails}
              allCarDetails={allCarDetails}
              CompanyImage={logo}
              handleInputCarDetails={handleInputCarDetails}
              CompanyName={CompanyName}
              businessType={businessType}
              isFindMyCar={isFindMyCar}
              passVehicleData={passVehicleData}
              policyTypeList={policyTypeList}
              setCarDetails={setCarDetails}
              ShowExpDateCalendar={ShowExpDateCalendar}
              setShowExpDateCalendar={setShowExpDateCalendar}
              ShowRegDateCalendar={ShowRegDateCalendar}
              setShowRegDateCalendar={setShowRegDateCalendar}
              CalendarDeactiveFlag={CalendarDeactiveFlag}
              ShowOdExpDateCalendar={ShowOdExpDateCalendar}
              test={test}
              setShowOdExpDateCalendar={setShowOdExpDateCalendar}
              setshowDeclaration={setshowDeclaration}
              showDeclaration={showDeclaration}
              setshowRegNoErr={setshowRegNoErr}
              showRegNoErr={showRegNoErr}
              setLoading1={setLoading1}
              loading1={loading1}
              setShowCngErr={setShowCngErr}
              showCngErr={showCngErr}
              handleRoutePform={handleRoutePform}
              digitAddonsplan={digitAddonsplan}
              setIsRegDateActive={setIsRegDateActive}
              carDetailsCopyState={carDetailsCopyState}
              setcarDetailsCopyState={setcarDetailsCopyState}
              handleCopiedDetails={handleCopiedDetails}
              setCheckboxList={setCheckboxList}
              CheckboxList={CheckboxList}


            />
          </Modal >
        )
      }
    </>
  );
};

export default QuotesContainer;
