import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import { combineReducers } from "redux";
import { loginReducer } from "./Reducers/loginAuthentication";
// import { myCustomApiService } from './api'

import {
  zunoQuickQuoteReducer,
  zunoFullQuoteReducer,
  zunoIssuePolicyReducer,
  zunoKycCustomerReducer,
  zunoManualKycReducer,
  zunoOnlinePaymentReducer,
  zunoPdfDownloadReducer,
} from "./Reducers/zunoApiReducer";
import { transactionURLReducer } from "./Reducers/transactionReducer";

import {
  rahejaGetIdvReducer,
  rahejaPremiumCalculationReducer,
  rahejaSaveQuoationReducer,
  rahejaGetTraceIdReducer,
  rahejaProposalCreationReducer,
  rahejaGenerateTransactionNumberReducer,
  rahejaGeneratePolicyNumberReducer,
} from "./Reducers/rahejaApiReducer";

import {
  shreeRamQuickQuoteReducer,
  shreeRamProposalReducer,
} from "./Reducers/shreeRamApiReducer";

import {
  iciciQuickQuoteReducer,
  iciciProposalReducer,
  iciciKycReducer,
  iciciKycAutoReducer,
  iciciCreateTxnReducer,
  icicipdfDownloadReducer,
} from "./Reducers/iciciApiReducer";

import {
  ackoQuickQuoteReducer,
  ackoProposalReducer,
  ackoGetLeadsReducer,
} from "./Reducers/ackoApiReducer";
import {
  shareLinkReducer,
  phonevalidationReducer,
} from "./Reducers/shareLinkReducer";

import {
  digitQuickQuoteReducer,
  digitQuickQuoteReducer12,
  digitCreateQuoteReducer,
  digitKycStatusReducer,
  digitPdfDownloadReducer,
} from "./Reducers/digitApiReducer";
import {
  sbiQuickQuoteReducer,
  sbiKycReducer,
  sbiKycAutoReducer,
  sbiFullQuoteReducer,
  sbiPolicyGenerateReducer,
  sbiPdfDownloadReducer,
} from "./Reducers/sbiApiReducer";

import {
  tataAutoKycReducer,
  tataManualKycReducer,
  tataProposalReducer,
  tataQuickQuoteReducer,
  tataOnlinePaymentReducer,
  tataVerifyPaymentReducer,
  tataPolicyDocumentReducer,
  tataOtpVerifyReducer,
  tataQuickQuoteReducerwithAddon,
} from "./Reducers/tataApiReducer";

import {
  hdfcQuickQuoteReducer,
  hdfcProposalReducer,
  hdfcKycAutoReducer,
  hdfcManualKycReducer,
  hdfcChecksumReducer,
  hdfcpdfDownloadReducer,
  hdfcProposalDocumentReducer
} from "./Reducers/hdfcApiReducer";

import {
  unitedQuickQuoteReducer,
  unitedProposalReducer,
  unitedAutoKycReducer,
  unitedCreateOrderReducer
} from "./Reducers/unitedApiReducer";

import {
  nationalQuickQuoteReducer,
  nationalKycReducer,
  nationalKycAutoReducer,
  nationalProposalReducer,
  nationalPdfDownloadReducer
} from "./Reducers/nationalApiReducer";

import { commonVehicleFetchApiReducer } from "./Reducers/commonVehicleFetchApiReducer";
import { bodyDataReducer, storePreviousData } from "./Reducers/bodyDataReducer";
import { dealerDataReducer } from "./Reducers/dealerDataReducer";
import { addOnDataDataReducer } from "./Reducers/addOnsDataReducer";
import { addonsReducer } from "./Reducers/addonsReducers";
// import { addonsReducer } from "./Reducers/addonsReducer";
import { companyNameReducer } from "./Reducers/companyNameReducer";
import { inspectionReducer } from "./Reducers/inspectionReducer";
import { razorCreateOrderReducer } from "./Reducers/razorCreateOrderReducer";
import {
  paymentWebhookReducer,
  paymentSendURLReducer,
} from "./Reducers/paymentWebhookReducer";
import {
  getModelReducer,
  getFuelTypeReducer,
  getVariantReducer,
} from "./Reducers/mmvReducer";

import {
  getRtoReducer,
  getStateReducer,
  getCityReducer,
  getCityReducer1,
  getLoanCompanyReducer,
  getInsurerReducer,
} from "./Reducers/stateReducer";
import { LoginTabDataReducer } from "./Reducers/bodyDataReducer";
// import { loginReducer } from "./Reducers/isLoginAuthentication";
import { viewPlanFlowReducer } from "./Reducers/isViewPlanFlowCompleted";
import { fmcContentType } from "./Reducers/FindMyCarContentType";
import { getpolicydataReducer, getRenewpolicydataReducer } from "./Reducers/policyDataReducer";
import { partnerPnaKycReducer } from "./Reducers/loginAuthentication";

const rootReducer = combineReducers({
  zunoQuickQuote: zunoQuickQuoteReducer.reducer,
  zunoIssuePolicy: zunoIssuePolicyReducer.reducer,
  zunoOnlinePayment: zunoOnlinePaymentReducer.reducer,
  rahejaIdv: rahejaGetIdvReducer.reducer,
  inspectDetails: inspectionReducer.reducer,
  zunoFullQuote: zunoFullQuoteReducer.reducer,
  zunoKyc: zunoKycCustomerReducer.reducer,
  zunoManualKyc: zunoManualKycReducer.reducer,
  zunoPdfDownload: zunoPdfDownloadReducer.reducer,
  rahejaPremium: rahejaPremiumCalculationReducer.reducer,
  rahejaSaveQuote: rahejaSaveQuoationReducer.reducer,
  rahejaProposal: rahejaProposalCreationReducer.reducer,
  rahejaTraceId: rahejaGetTraceIdReducer.reducer,
  rahejaTransactionNumber: rahejaGenerateTransactionNumberReducer.reducer,
  rahejaPolicyNumber: rahejaGeneratePolicyNumberReducer.reducer,
  shreeRamQuickQuote: shreeRamQuickQuoteReducer.reducer,
  shreeRamProposal: shreeRamProposalReducer.reducer,
  iciciQuickQuote: iciciQuickQuoteReducer.reducer,

  iciciProposal: iciciProposalReducer.reducer,
  iciciKycData: iciciKycReducer.reducer,
  iciciKycAutoData: iciciKycAutoReducer.reducer,
  iciciCreateTransaction: iciciCreateTxnReducer.reducer,
  icicipdfDownload: icicipdfDownloadReducer.reducer,
  ackoQuickQuote: ackoQuickQuoteReducer.reducer,
  ackoProposal: ackoProposalReducer.reducer,
  ackoGetLeads: ackoGetLeadsReducer.reducer,
  digitQuickQuote: digitQuickQuoteReducer.reducer,
  digitQuickQuote12: digitQuickQuoteReducer12.reducer,
  digitProposal: digitCreateQuoteReducer.reducer,
  digitkycStatus: digitKycStatusReducer.reducer,

  digitPolicyPdfDowload: digitPdfDownloadReducer.reducer,
  sbiQuickQuote: sbiQuickQuoteReducer.reducer,
  getVehicleData: commonVehicleFetchApiReducer.reducer,
  bodyData: bodyDataReducer.reducer,
  previousData: storePreviousData.reducer,
  dealerData: dealerDataReducer.reducer,
  addOnData: addOnDataDataReducer.reducer,
  addOnDataPlan: addonsReducer.reducer,
  companyName: companyNameReducer.reducer,
  razorOrder: razorCreateOrderReducer.reducer,
  sbiKycData: sbiKycReducer.reducer, // this is manual CKYC API for SBI
  abiKycAutoData: sbiKycAutoReducer.reducer, // this is auto ckyc API for SBI
  sbiFullQuote: sbiFullQuoteReducer.reducer,
  getModel: getModelReducer.reducer,
  getFuel: getFuelTypeReducer.reducer,
  getVariant: getVariantReducer.reducer,
  sbiPolicyGenerate: sbiPolicyGenerateReducer.reducer,
  sbiPdfData: sbiPdfDownloadReducer.reducer,
  paymentWebhook: paymentWebhookReducer.reducer,
  paymentSendURLhook: paymentSendURLReducer.reducer,
  getRtoDetails: getRtoReducer.reducer,
  getStateDetails: getStateReducer.reducer,
  getCityDetails: getCityReducer.reducer,
  getCityDetails1: getCityReducer1.reducer,
  getFinancierNameDetails: getLoanCompanyReducer.reducer,
  getInsurerDetails: getInsurerReducer.reducer,
  getLoggedInStatus: loginReducer.reducer,
  tataQuickQuote: tataQuickQuoteReducer.reducer,
  tataQuickQuotewithAddon: tataQuickQuoteReducerwithAddon.reducer,
  tataProposalQuote: tataProposalReducer.reducer,
  tataAutoKyc: tataAutoKycReducer.reducer,
  tataManualKyc: tataManualKycReducer.reducer,
  tataOnlinePayment: tataOnlinePaymentReducer.reducer,
  tataVerifyPayment: tataVerifyPaymentReducer.reducer,
  tataPolicyDocument: tataPolicyDocumentReducer.reducer,
  tataOtpVerify: tataOtpVerifyReducer.reducer,
  getviewPlanFlowStatus: viewPlanFlowReducer.reducer,
  getTransactionStatus: transactionURLReducer.reducer,
  hdfcQuickQuote: hdfcQuickQuoteReducer.reducer,
  hdfcKycAutoData: hdfcKycAutoReducer.reducer,
  hdfcManualKycData: hdfcManualKycReducer.reducer,
  hdfcProposalQuote: hdfcProposalReducer.reducer,
  hdfcChecksumData: hdfcChecksumReducer.reducer,
  hdfcpdfDownload: hdfcpdfDownloadReducer.reducer,
  hdfcProposalDownload: hdfcProposalDocumentReducer.reducer,
  getFmcContentType: fmcContentType.reducer,
  unitedQuickQuote: unitedQuickQuoteReducer.reducer,
  unitedProposalQuote: unitedProposalReducer.reducer,
  unitedAutoKyc: unitedAutoKycReducer.reducer,
  unitedCreateOrder:unitedCreateOrderReducer.reducer,
  nationalQuickQuote: nationalQuickQuoteReducer.reducer,
  nationalKycAutoData: nationalKycAutoReducer.reducer,
  nationalKycData: nationalKycReducer.reducer,
  nationalProposalQuote: nationalProposalReducer.reducer,
  nationalPdfDownload: nationalPdfDownloadReducer.reducer,
  // shareLink
  shareLink: shareLinkReducer.reducer,
  getLoggedInStatus: loginReducer.reducer,
  getPolicyData: getpolicydataReducer.reducer,
  getRenewPolicyUrl: getRenewpolicydataReducer.reducer,
  isPhoneNumberValid: phonevalidationReducer.reducer,
  getPartnerPnaKycData: partnerPnaKycReducer.reducer,
  LoginTabData: LoginTabDataReducer.reducer
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // thunk: {
      //   extraArgument: myCustomApiService,
      // },
      serializableCheck: false,
    }),
});

export default store;

export const persistor = persistStore(store);
