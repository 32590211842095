import React from "react";
import calender from "../../../Assets/Images/calendernew.svg";
import "./CustomDateField.css";

const CustomDateField = (props) => {
  const {
    dateValue,
    event,
    className,
    setCalendarReveal,
    btnId,
    textId,
    imgId,
    errors,
    placeholder,
    placeholderId,
    isDisabled,
    activeVal,
    setIsRegDateActive,
    CalendarDeactiveFlag
  } = props;
  let formattedDateString = ""
  if (dateValue) {
    const formattedDate = new Date(dateValue);
    const day = formattedDate.getDate();
    const month = formattedDate.toLocaleString("en-us", { month: "short" });
    const year = formattedDate.getFullYear();
    formattedDateString = `${day}-${month}-${year}`;
  }

  const style22 = {
    cursor: "progress"
  }

  const activeStyle = {
    cursor: "progress"
  };

  const inactiveStyle = {

  };

  return (
    <>
      <div
        className={className}
        style={CalendarDeactiveFlag ? activeStyle : inactiveStyle}
        onClick={(e) => {
          delete errors?.dob;

          if (!isDisabled && !CalendarDeactiveFlag) {

            setCalendarReveal(true);
          }
          if (setIsRegDateActive) {
            setIsRegDateActive(true)
          }
          if (activeVal) {
            setIsRegDateActive(false)
          }
        }}

        id={btnId}
      >
        {formattedDateString !== "NaN-Invalid Date-NaN" &&
          formattedDateString && (
            <p className="headTxtClassPform headTxtClassPform-top">
              {placeholder}
            </p>
          )}
        {formattedDateString !== "NaN-Invalid Date-NaN" && formattedDateString !== "" ? (
          <p id={textId}>{formattedDateString}</p>
        ) : (
          <p id={placeholderId} className="headTxtClassPform">
            {placeholder}
          </p>
        )}
        <img src={calender} alt="" srcSet="" id={imgId} style={{ color: "#5086ff" }} />
      </div>
    </>
  );
};

export default CustomDateField;
