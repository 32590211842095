import React, { useState, useEffect } from "react";
import "./InputFields.css";

const InputFields = (props) => {
  const {
    value,
    placeholder,
    id,
    type,
    onChangeEvent,
    className,
    name,
    isDisabled,
    errClassName,
    errState,
    errMsg,
    showRegNoErr,
    containerID,
    label,
    hideLabel,

  } = props;

  const [isActive, setIsActive] = useState(false);
  const [handleError, setHandleError] = useState(true)

  useEffect(() => {
    if (value === "" || value === null || value === undefined) {
      setIsActive(false);
    } else {
      setIsActive(true);
    }
    return () => { };
  }, [value]);

  const Handle_E = (e) => {
    if (type == "number") {
      ["ArrowUp", "ArrowDown", "e", "E", ".", 'touchstart'].includes(e.key) && e.preventDefault();
    }

    if (type == "pincode") {
      ["ArrowUp", "ArrowDown", "e", "E", ".", 'touchstart'].includes(e.key) && e.preventDefault();
    }


    if (name == "pincode") {
      ["+", "-", "."].includes(e.key) && e.preventDefault();
    }
    if (name == "mobileNo" || name == "phoneNumber") {
      ["+", "-", "."].includes(e.key) && e.preventDefault();
    }
    if (name == "kycPanNumber") {
      ["?", "/", ".", '+', ','].includes(e.key) && e.preventDefault();
    }
    if (name === "firstName") {
      ["/", "|", ",", "?"].includes(e.key) && e.preventDefault();
    }

    if (name === "lastName") {
      ["/", "|", ",", "?"].includes(e.key) && e.preventDefault();
    }

  };

  const style = {
    borderColor: {
      border: showRegNoErr && '1px solid red'
    }
  }

  return (
    <>
      <div>
        <div id={containerID ? containerID : "float-label"}>
          <input
            type={type}
            name={name}
            id={id}
            style={style.borderColor}
            value={value ? value : ""}
            className={errState ? errClassName + " " + className : className}
            onChange={(e) => { onChangeEvent(e); }}
            onKeyDown={Handle_E}
            onWheel={(event) => event.target.blur()}
            disabled={isDisabled | false}
            placeholder={label}

          />
          {
            !containerID && (
              <label className={isActive ? "Active" : ""} htmlFor={id}>
                {placeholder}
              </label>
            )
          }

        </div>
        {
          errState ? <p className="err-msg">{errMsg} </p> : ""
        }
      </div>
    </>
  );
};

export default InputFields;
