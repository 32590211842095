import {
  nationalQuickQuoteAction,
  nationalKyc,
  nationalKycAuto,
  nationalFullQuoteAction,
  nationalPdfDownloadAction
} from "../Actions/nationalApiAction";
import { createSlice } from "@reduxjs/toolkit";

export const nationalQuickQuoteReducer = createSlice({
  name: "nationalQuickQuote",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(nationalQuickQuoteAction.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(nationalQuickQuoteAction.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(nationalQuickQuoteAction.rejected, (state, action) => {
      state.isError = true;
      state.isLoading = false;
    });
  },
  reducers: {
    resetNationalQuoteAction: (state, action) => {
      state.data = undefined
    }
  }
});

export const { resetNationalQuoteAction } = nationalQuickQuoteReducer.actions;

export const nationalKycAutoReducer = createSlice({
  name: "nationalKycAutoReducer",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },

  extraReducers: (builder) => {
    builder.addCase(nationalKycAuto.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(nationalKycAuto.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(nationalKycAuto.rejected, (state, action) => {
      console.error("Error", action.payload);
      state.isError = true;
      state.isLoading = false;
    });
  },
  reducers: {
    resetnatioanlKycAutoAction: (state, action) => {
      state.data = undefined
    },
    addAutoNatioanlKycDataAction: (state, action) => {
      state.data = action.payload;
    },
  }
});

export const { resetnatioanlKycAutoAction, addAutoNatioanlKycDataAction } = nationalKycAutoReducer.actions;

export const nationalKycReducer = createSlice({
  name: "nationalKycReducer",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(nationalKyc.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(nationalKyc.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.data = action.payload;
    });
    builder.addCase(nationalKyc.rejected, (state, action) => {
      console.error("Error", action.payload);
      state.isError = true;
      state.isLoading = false;
    });
  },
  reducers: {
    resetnationalKycAction: (state, action) => {
      state.data = undefined;
      state.isError = false;
      state.isLoading = false;

    },
    addNationalManualKycDataAction: (state, action) => {
      state.data = action.payload;
    },
  }
});

export const { resetnationalKycAction, addNationalManualKycDataAction } = nationalKycReducer.actions;

export const nationalProposalReducer = createSlice({
  name: "nationalProposal",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(nationalFullQuoteAction.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(nationalFullQuoteAction.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(nationalFullQuoteAction.rejected, (state, action) => {
      console.error("Error", action.payload);
      state.isError = true;
      state.isLoading = false;
    });
  },
  reducers: {
    resetnationalFullQuoteAction: (state, action) => {
      state.data = undefined
    }
  }
});

export const { resetnationalFullQuoteAction } = nationalProposalReducer.actions;

export const nationalPdfDownloadReducer = createSlice({
  name: "nationalPdfDownload",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(nationalPdfDownloadAction.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(nationalPdfDownloadAction.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(nationalPdfDownloadAction.rejected, (state, action) => {
      console.error("Error", action.payload);
      state.isError = true;
      state.isLoading = false;
    });
  },
  reducers: {
    resetPdfDownloadAction: (state, action) => {
      state.data = undefined
    }
  }
});
export const { resetPdfDownloadAction } = nationalPdfDownloadReducer.actions;
