import React, { useState, useEffect } from "react";
import "./QuotesContainer.css";
import Botton from "../Button/Button";
import Plancard from "./PlancardBike.jsx";
import Modal from "react-modal";
import ConfirmDetailsBike from "./ConfirmDetailsBike.jsx";
import { getCompanyName } from "../../redux/Reducers/companyNameReducer.js";
import { alladdOnDataDataAction } from "../../redux/Reducers/addOnsDataReducer";
import { isInitialAction } from "../../redux/Reducers/inspectionReducer";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import cross from "../../Assets/Images/Close.svg";
import { useDispatch } from "react-redux";
import InputFields from "../InputFields/InputFields";
import { bikeDetailsCalculateTotalPremium } from "../../utils/commonUtils.js";
import { transactionURLAction } from "../../redux/Actions/TransactionAction";
import {
  zunoQuickQuoteAction,
} from "../../redux/Actions/zunoApiAction";
import {
  iciciQuickQuoteAction,
} from "../../redux/Actions/iciciApiAction";
import {
  tataQuickQuoteAction,
} from "../../redux/Actions/tataApiAction";

import { ackoQuickQuoteAction } from "../../redux/Actions/ackoApiAction.js";
import { hdfcQuickQuoteAction } from "../../redux/Actions/hdfcApiAction.js";
import { digitQuickQuoteAction } from "../../redux/Actions/digitApiAction.js";
import { unitedQuickQuoteAction } from "../../redux/Actions/unitedApiAction.js";
import { shreeRamQuickQuoteAction } from "../../redux/Actions/shreeRamApiAction.js";
import { sbiQuickQuoteAction } from "../../redux/Actions/sbiApiAction.js";
import { nationalQuickQuoteAction } from "../../redux/Actions/nationalApiAction.js";
import Close from "../../Assets/Images/Close.svg";
import loader2 from "../../Assets/Loader/Loader.gif";
import caution from "../../Assets/Images/cautionImg.svg"
import ButtonLoader from "../Pages/Loader/Button Loader/ButtonLoader.jsx";


const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const customStylesForMobile = {
  content: {
    top: "auto",
    left: "0",
    right: "0",
    bottom: "0",
    margin: "0",
    transform: "none",
    borderRadius: "10px 10px 0 0",
    padding: "20px",
    height: "auto",
  },
};


Modal.setAppElement("#root");

const QuotesContainer = (props) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [ShowRegNumModal, setShowRegNumModal] = useState(false);
  // const [NoRespforCom, setNoREspforCom] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [showRegNoErr, setshowRegNoErr] = useState(false);
  const [CheckboxList, setCheckboxList] = useState({
    declaration: true,
  });

  const {
    PolicyPlanType,
    carDetails,
    handleInputCarDetails,
    businessType,
    vehicleData,
    ShowPcSumInsuredField,
    ShowAccesoriesField,
    isFindMyCar,
    setShowBottomTab,
    policyTypeOption,
    passVehicleData,
    policyTypeList,
    setLoaderFromConfirmPg,
    CompanyWiseLoader,
    setCarDetails,
    ShowExpDateCalendar,
    setShowExpDateCalendar,
    ShowRegDateCalendar,
    setShowRegDateCalendar,
    ShowOdExpDateCalendar,
    setShowOdExpDateCalendar,
    carsAge,
    setIsRegDateActive,
    CalendarDeactiveFlag,
    setformattedRegDate,
    formattedRegDate,
    formattedpolicyExpDate,
    formattedODexpDate,
    carDetailsCopyState,
    setcarDetailsCopyState,
    allIDVValues,
    handleCopiedDetails,
    test,
  } = props;


  const [allCarDetails, setallCarDetails] = useState({});
  const [logo, setlogo] = useState("");
  const [CompanyName, setCompanyName] = useState(null);

  const [showDeclaration, setshowDeclaration] = useState(true);
  const [VerifyTabActive, setVerifyTabActive] = useState(false);
  const [StoreVehicleNumber, setStoreVehicleNumber] = useState(carDetails?.vehicleNumber);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  let bodyData = useSelector((state) => {
    return state.bodyData;
  });

  // console.log(bodyData?.data?.vehicleType,"bgfrrtrddf");

  let vehicleTypeCheck = bodyData?.data?.vehicleType;

  const getSbiQuickQuoteData = useSelector((state) => {
    return state?.sbiQuickQuote;
  });

  const shreeRamQuote = useSelector((state) => {
    return state?.shreeRamQuickQuote;
  });

  const digitQuote = useSelector((state) => {
    return state?.digitQuickQuote;
  });

  const iciciQuote = useSelector((state) => {
    return state?.iciciQuickQuote;
  });

  const hdfcQuote = useSelector((state) => {
    return state?.hdfcQuickQuote;
  });

  const ackoQuote = useSelector((state) => {
    return state?.ackoQuickQuote;
  });

  // ZUNO
  const zunoQQuote = useSelector((state) => {
    return state?.zunoQuickQuote;
  });

  // TATA
  const tataQQuote = useSelector((state) => {
    return state?.tataQuickQuote;
  });

  // UNITED
  const unitedQQuote = useSelector((state) => {
    return state?.unitedQuickQuote;
  });



  let quoteData = {};

  if (CompanyName == "sbi") {
    // companyImg = sbi
    quoteData = getSbiQuickQuoteData;
  } else if (CompanyName == "shreeram") {
    // companyImg = shreeRam
    quoteData = shreeRamQuote;
  } else if (CompanyName == "digit") {
    // companyImg = digit
    quoteData = digitQuote;
  } else if (CompanyName == "icici") {
    // companyImg = icici
    quoteData = iciciQuote
  } else if (CompanyName == "hdfc") {
    // companyImg = hdfc
    quoteData = hdfcQuote
  } else if (CompanyName == "acko") {
    // companyImg = acko
    quoteData = ackoQuote
  } else if (CompanyName == "zuno") {
    // companyImg = zuno
    quoteData = zunoQQuote
  } else if (CompanyName == "tata") {
    // companyImg = tata
    quoteData = tataQQuote?.data?.data?.quote
  } else if (CompanyName == "united") {
    // companyImg = united
    quoteData = unitedQQuote
  }


  function openModal(logo, carDetails, companyName) {
    setallCarDetails(carDetails);
    setlogo(logo);
    setCompanyName(companyName);
    setIsOpen(true);
    setShowBottomTab(false);
  }

  function closeModal() {
    setIsOpen(false);
    setShowBottomTab(true);
  }
  useEffect(() => {
    setcarDetailsCopyState((prev) => ({
      ...prev,
      vehicleNumberCopy: carDetails?.vehicleNumber
    }))
  }, [])

  const triggerVerifyTab = (companyImg, carDetails, companyName) => {
    dispatch(
      getCompanyName({
        companyName: companyName,
      })
    );
    setlogo(companyImg);
    setCompanyName(companyName);
    setallCarDetails(carDetails);

    if (carDetails?.vehicleNumber.length < 8 && carDetails?.typeOfBusiness !== "New") {
      setShowRegNumModal(true);
      setshowRegNoErr(true);
    } else {
      setVerifyTabActive(true);
      setShowRegNumModal(false);
      setshowRegNoErr(false);
    }

    // setVerifyTabActive(true);
  }
  const month = new Date(carDetails.policyRegDate).toLocaleString("default", {
    month: "long",
  });

  const year = new Date(carDetails.policyRegDate).toLocaleString("default", {
    year: "numeric",
  });

  useEffect(() => {
    if (VerifyTabActive || loading1 || ShowRegNumModal || modalIsOpen ) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
    return () => {
      document.body.style.overflow = "";
    };
  }, [VerifyTabActive, loading1, ShowRegNumModal, modalIsOpen]);

  useEffect(() => {

    if (modalIsOpen) {
      setLoaderFromConfirmPg(true);
    } else {
      setLoaderFromConfirmPg(false);
    }

    if (modalIsOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    // Cleanup function to reset the overflow property when the component unmounts
    return () => {
      document.body.style.overflow = '';
    };

  }, [modalIsOpen])

  const verifyDetailsContent = [
    {
      id: 1,
      heading: "Vehicle Registration Number",
      value: carDetails?.vehicleNumber,
      headingTwo: "",
      valueTwo: "",
    },
    {
      id: 2,
      heading: businessType === "New" ? "Policy Start Date" : "Registraion Date",
      value: formattedRegDate,
      headingTwo: "Manufacturing Date",
      valueTwo: month + " " + year,
    },
    {
      id: 3,
      heading: "Previous Policy Type",
      value: carDetails?.PolicyTypeValue,
      headingTwo: "Previous Policy Expiry Date",
      valueTwo: businessType !== "New" ? (carDetails?.prevPolicyType === 'Bundled' ? formattedODexpDate : formattedpolicyExpDate) : "", //
    },
    {
      id: 4,
      heading: "Claim in previous policy",
      value: carDetails?.isClaimPrevPolicy == true ? "Yes" : "No",
      headingTwo: "Previous No claim bonus",
      valueTwo: carDetails?.isClaimPrevPolicy == true ? "N/A" : carDetails?.prevNcb + "%",
    },
  ]

  function openModal(logo, carDetails, companyName) {
    setIsOpen(true);
    setallCarDetails(carDetails);
    setlogo(logo);
    setCompanyName(companyName);
    setShowBottomTab(false);
    setVerifyTabActive(false)
    let zoomLevel;
    if (window.innerWidth <= 400) {
      zoomLevel = 1;
      document.body.style.zoom = zoomLevel?.toString();
    }
  }

  function closeModal() {
    setcarDetailsCopyState((...prev) => ({
      ...prev,
      vehicleNumberCopy: carDetails?.vehicleNumber?.toUpperCase() || "",
      makeCopy: carDetails?.make || "",
      modelCopy: carDetails?.model || "",
      varientCopy: carDetails?.varient || "",
      fuelTypeCopy: carDetails?.fuelType || "",
      ccCopy: carDetails?.cc || "",
      regDateCopy: carDetails?.policyRegDate || "",
      prevPolicyTypeCopy: carDetails?.prevPolicyType || "",
      policyExpCopy: carDetails?.policyExpDate || "",
      odPolicyExpCopy: carDetails?.prevPolicyODexpDate || "",
      tpPolicyExpCopy: carDetails?.prevPolicyTPexpDate || "",
      isClaimPrevPolicyCopy: carDetails?.isClaimPrevPolicy,
      prevNcbCopy: carDetails?.prevNcb,
      newNcbCopy: carDetails?.newNcb,
      electricalAccesoriesCopy: carDetails?.electricalAccesories || "",
      nonElectricalAccesoriesCopy: carDetails?.nonElectricalAccesories || "",
      policyTypeValueCopy: carDetails?.PolicyTypeValue || "",
      idvValueCopy: carDetails?.idvValue || "",
      quotePackageNameCopy: carDetails?.quotePackageName || "",
      quotePackageNameDigitCopy: carDetails?.quotePackageNameDigit || "",
      quotePackageNameICICICopy: carDetails?.quotePackageNameICICI || "",
      cngKitValuecopy: carDetails?.cngKitValue || "",
      isCngLpgVehicleTypecopy: carDetails?.isCngLpgVehicleType || false,
    }))


    setIsOpen(false);
    setShowBottomTab(true);
    let zoomLevel;
    if (window.innerWidth <= 400) {
      zoomLevel = 0.9;
      document.body.style.zoom = zoomLevel?.toString();
    }
  }
  // useEffect(()=>{
  //   etcarDetailsCopyState((prev)=>({
  //     s...prev,
  //     vehicleNumberCopy:carDetails?.vehicleNumber
  //      }))
  // },[])





  const handleRoutePform = async (e) => {
    
    setCarDetails((prevItems) => ({
      ...prevItems,
      // vehicleNumber: carDetailsCopyState?.vehicleNumberCopy,
      prevPolicyType: carDetailsCopyState?.prevPolicyTypeCopy,
      policyExpDate: carDetailsCopyState?.policyExpCopy,
      prevPolicyODexpDate: carDetailsCopyState?.odPolicyExpCopy,
      prevPolicyTPexpDate: carDetailsCopyState?.tpPolicyExpCopy,
      isClaimPrevPolicy: carDetailsCopyState?.isClaimPrevPolicyCopy,
      prevNcb: carDetailsCopyState?.prevNcbCopy,
      newNcb: carDetailsCopyState?.newNcbCopy,
      make: carDetailsCopyState?.makeCopy,
      model: carDetailsCopyState?.modelCopy,
      varient: carDetailsCopyState?.varientCopy,
      fuelType: carDetailsCopyState?.fuelTypeCopy,
      cc: carDetailsCopyState?.ccCopy,
      policyRegDate: carDetailsCopyState?.regDateCopy,
      mfgDate: carDetailsCopyState?.mfgDatecopy,
      PolicyTypeValue: carDetailsCopyState?.policyTypeValueCopy,
      cngKitValue: carDetailsCopyState?.cngKitValuecopy
    }));


    let flag = true;
    if (carDetails) {
      await dispatch(alladdOnDataDataAction(carDetails));
    }
    let regnumber = carDetails?.vehicleNumber;
    if (regnumber?.length < 8) {
      setshowRegNoErr(true)
      flag = false;
    }
    if (carDetails?.typeOfBusiness?.toUpperCase() !== "NEW") {
      if (flag && showDeclaration && CheckboxList?.declaration) {
        // setLoading1(true)

        let data = {
          uuid: id,
          companyName: CompanyName,
          type: "update_company_name",
        };
        setLoading1(true);
        await dispatch(transactionURLAction(data));
        let data2 = {
          ...bodyData.data,
          ...{ coverage: [test] },
        }


        let zoomLevel;
        if (window.innerWidth <= 400) {
          zoomLevel = 0.9;
          document.body.style.zoom = zoomLevel?.toString();
        }
        navigate("/Propsal-Form-Bike?id=" + id, {
          state: {
            allCarDetails: JSON.parse(JSON.stringify(allCarDetails)),
            logo: logo,
            CompanyName: CompanyName,
          },
        });
        setLoading1(false);
      }
    } else {
      if (CheckboxList?.declaration) {
        setLoading1(true);
        let data = {
          uuid: id,
          companyName: CompanyName,
          type: "update_company_name",
        };
        setLoading1(true)
        await dispatch(transactionURLAction(data));
        let data2 = {
          ...bodyData.data,
          vehicleNumber: carDetailsCopyState?.vehicleNumberCopy,
          ...{ coverage: [test] },
        }

        let zoomLevel;
        if (window.innerWidth <= 400) {
          zoomLevel = 0.9;
          document.body.style.zoom = zoomLevel?.toString();
        }
        navigate("/Propsal-Form-Bike?id=" + id, {
          state: {
            allCarDetails: JSON.parse(JSON.stringify(allCarDetails)),
            logo: logo,
            CompanyName: CompanyName,
          },
        });
      }
      setLoading1(false);
    }
  };


  const filteredContent = businessType === "New"
    ? verifyDetailsContent.filter(item => item.id === 1 || item.id === 2)
    : verifyDetailsContent;

  

  return (
    <>
      
      {/* <div id="cover-spin" style={{ display: loading1 ? "" : "none" }}>
        <img src={loader2} className="gifLoader"></img>
      </div> */}

      <form action="" onSubmit={(e) => { e.preventDefault(); }}>
        <div className="section_bottomContent_vp">
          <div>
            <Plancard
              openModal={openModal}
              planType={carDetails?.PolicyTypeValue}
              carDetails={carDetails}
              logo={logo}
              handleInputCarDetails={handleInputCarDetails}
              setShowBottomTab={setShowBottomTab}
              vehicleAge={carsAge}
              CompanyName={CompanyName}
              businessType={businessType}
              isFindMyCar={isFindMyCar}
              passVehicleData={passVehicleData}
              policyTypeList={policyTypeList}
              setCarDetails={setCarDetails}
              ShowExpDateCalendar={ShowExpDateCalendar}
              setShowExpDateCalendar={setShowExpDateCalendar}
              ShowRegDateCalendar={ShowRegDateCalendar}
              setShowRegDateCalendar={setShowRegDateCalendar}
              ShowOdExpDateCalendar={ShowOdExpDateCalendar}
              setShowOdExpDateCalendar={setShowOdExpDateCalendar}
              CompanyWiseLoader={CompanyWiseLoader}
              setIsRegDateActive={setIsRegDateActive}
              CalendarDeactiveFlag={CalendarDeactiveFlag}
              triggerVerifyTab={triggerVerifyTab}
              allIDVValues={allIDVValues}
              loading1={loading1}
            />
          </div>
        </div>

        {/* {
          !CompanyWiseLoader && (
            <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              style={customStyles}
              contentLabel="Example Modal"
              id=""
            >
              <ConfirmDetailsBike
                type="submit"
                closeModal={closeModal}
                vehicleData={vehicleData}
                planType={carDetails?.PolicyTypeValue}
                carDetails={carDetails}
                allCarDetails={allCarDetails}
                CompanyImage={logo}
                handleInputCarDetails={handleInputCarDetails}
                CompanyName={CompanyName}
                businessType={businessType}
                isFindMyCar={isFindMyCar}
                passVehicleData={passVehicleData}
                policyTypeList={policyTypeList}
                setCarDetails={setCarDetails}
                ShowExpDateCalendar={ShowExpDateCalendar}
                setShowExpDateCalendar={setShowExpDateCalendar}
                ShowRegDateCalendar={ShowRegDateCalendar}
                setShowRegDateCalendar={setShowRegDateCalendar}
                ShowOdExpDateCalendar={ShowOdExpDateCalendar}
                setShowOdExpDateCalendar={setShowOdExpDateCalendar}
                CompanyWiseLoader={CompanyWiseLoader}
                setIsRegDateActive={setIsRegDateActive}
                setshowDeclaration={setshowDeclaration}
              showDeclaration={showDeclaration}
              setshowRegNoErr={setshowRegNoErr}
              showRegNoErr={showRegNoErr}
              />
            </Modal>
          )
        } */}

        {VerifyTabActive && (
          <div
            className="edit-tabs-container-mob-height"
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
          >
            {VerifyTabActive && (
              <div className="active-bottom-edit-IDV-tabs">
                <div className="edit-tabs-container">
                  <div className="edit-tabs-container-head edit-tabs-container-head-bg">
                    <p>Confirm your Policy Details</p>
                    <p>Ensure Premium Accuracy</p>
                    <img src={cross} onClick={() => setVerifyTabActive(false)} />
                  </div>

                  {
                    filteredContent?.map((data, key) => (
                      <div key={key} className="verify-tab-cd">
                        <div className="verify-tab-cd-child">
                          <p> {data.heading} </p>
                          <p> {data.value} </p>
                        </div>
                        <div className="verify-tab-cd-child">
                          <p> {data.headingTwo} </p>
                          <p> {data.valueTwo} </p>
                        </div>
                      </div>
                    ))
                  }

                  <div className="btn-vd-container">
                    {loading1 ? <ButtonLoader /> : <Botton className="btn-verifyDetails-p btn-vd" text="Proceed & Buy" event={(e) => handleRoutePform(e)} />}
                    <Botton className="btn-verifyDetails-s btn-vd" text="Edit Details" event={() => openModal(logo, carDetails, CompanyName)} />
                  </div>

                </div>
              </div>
            )}
          </div>
        )}

        {ShowRegNumModal && (
          <div
            className="edit-tabs-container-mob-height"
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
          >
            <div className="active-bottom-edit-IDV-tabs" style={{ height: "20rem" }} >
              <div className="edit-tabs-container">
                <div className="edit-tabs-container-head edit-tabs-container-head-bg">
                  <p>Confirm your Vehicle Number</p>
                  <p>Ensure Premium Accuracy</p>
                  <img src={cross} onClick={() => setShowRegNumModal(false)} />
                </div>

                <p style={{ fontWeight: "500", marginBottom: ".5rem" }} >Vehicle Number</p>

                <InputFields
                  type="text"
                  className="inputs-form uppercaseText"
                  onChangeEvent={handleInputCarDetails}
                  showRegNoErr={showRegNoErr}
                  value={(carDetails?.vehicleNumber).toUpperCase()}
                  name="vehicleNumber"
                  id="vehicleNumber"
                />

                <div className="btn-vd-container btn-regNo-container">
                  <Botton
                    className="btn-verifyDetails-p btn-vd"
                    text="Submit"
                    event={(e) => triggerVerifyTab(logo, carDetails, CompanyName)}
                  />
                </div>

              </div>
            </div>
          </div>
        )}

        {!CompanyWiseLoader && (
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
            id=""
          >
            <ConfirmDetailsBike
              type="submit"
              closeModal={closeModal}
              vehicleData={vehicleData}
              planType={carDetails?.PolicyTypeValue}
              carDetails={carDetails}
              allCarDetails={allCarDetails}
              CompanyImage={logo}
              handleInputCarDetails={handleInputCarDetails}
              CompanyName={CompanyName}
              businessType={businessType}
              isFindMyCar={isFindMyCar}
              passVehicleData={passVehicleData}
              policyTypeList={policyTypeList}
              setCarDetails={setCarDetails}
              ShowExpDateCalendar={ShowExpDateCalendar}
              setShowExpDateCalendar={setShowExpDateCalendar}
              ShowRegDateCalendar={ShowRegDateCalendar}
              setShowRegDateCalendar={setShowRegDateCalendar}
              ShowOdExpDateCalendar={ShowOdExpDateCalendar}
              setShowOdExpDateCalendar={setShowOdExpDateCalendar}
              CompanyWiseLoader={CompanyWiseLoader}
              setIsRegDateActive={setIsRegDateActive}
              setshowDeclaration={setshowDeclaration}
              showDeclaration={showDeclaration}
              setshowRegNoErr={setshowRegNoErr}
              showRegNoErr={showRegNoErr}
              handleRoutePform={handleRoutePform}
              setCheckboxList={setCheckboxList}
              CheckboxList={CheckboxList}
              handleCopiedDetails={handleCopiedDetails}
              carDetailsCopyState={carDetailsCopyState}
              setcarDetailsCopyState={setcarDetailsCopyState}
              loading1={loading1}
              setLoading1={setLoading1}
            />
          </Modal >
        )}

      </form>

    </>
  );
};

export default QuotesContainer;