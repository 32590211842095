import React from 'react'
import creative from "../../Assets/Images/saifAcko.png"
import acko from "../../Assets/Images/acko-logo.png"
import apple from "../../Assets/Images/apple.svg"
import playStore from "../../Assets/Images/playStore.svg"

const AckoCreative = () => {

    return (
        <div className="acko-creative-container">
            <div className="acko-creative-content">
                <p>
                    Get Roadside Assistance
                    <br />
                    and Easy Claim Settlement
                    <br />
                    with ACKO Car Insurance
                </p>
                <div className="acko-logo-container">
                    <img src={acko} className='acko-logo-creative' alt="" srcset="" />
                    <p>Welcome Change</p>
                    <div className='download-container'>
                        <p>Download now</p>
                        <div className="download-container-img">
                            <img src={apple} alt="" srcset="" /> |
                            <img src={playStore} alt="" srcset="" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="acko-creative-img">
                <img src={creative} alt="" srcset="" />
            </div>
        </div>
    )
}

export default AckoCreative
