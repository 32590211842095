import React from 'react'
import Navbar from "../../Navbar/Navbar";
import Footer from "../../Footer/Footer";
import car from "../../../Assets/Images/UsedCar.png";
import FinancialProtection from "../../../Assets/Images/FinancialProtection.svg";
import LiabilityCoverage from "../../../Assets/Images/LiabilityCoverage.svg";
import ProtectionDrivers from "../../../Assets/Images/ProtectionfromUninsuredDrivers.svg";
import LegalRequirement from "../../../Assets/Images/LegalRequirement.svg";
import Resale from "../../../Assets/Images/ResaleValuerrr.png";
import peace from "../../../Assets/Images/peaceOfMind.svg";
import Card from "../../Card/Card";
import mobile from "../../../Assets/Images/mobileContent.png";
import calculator from "../../../Assets/Images/calculator.png";
import claim from "../../../Assets/Images/claim.svg";
import emi from "../../../Assets/Images/emi.svg";
import cashless from "../../../Assets/Images/cashless.svg";
import assistance from "../../../Assets/Images/assistance.svg";
import policy from "../../../Assets/Images/policy.svg";
import refund from "../../../Assets/Images/refund.svg";
import tracking from "../../../Assets/Images/tracking.svg";
import standalone from "../../../Assets/Images/standalone.svg";
import success from "../../../Assets/Images/success.svg";
import tata from "../../../Assets/Partners_logo/TATA AIG.png";
import digit from "../../../Assets/Partners_logo/digit.svg";
import icici from "../../../Assets/Partners_logo/icici lombard.svg";
import hdfc from "../../../Assets/Partners_logo/hdfc.png";
import fail from "../../../Assets/Images/failure.svg";
import assesments from "../../../Assets/Images/research.svg";
import comparison from "../../../Assets/Images/coverageLimit.svg";
import rightPolicy from "../../../Assets/Images/onlinePayment.svg";
import carDetails from "../../../Assets/Images/carDetails.svg";
import Timeline from "../../InputFields/Timeline/Timeline";
import lady from "../../../Assets/Images/ladyTwo.png";
import Partners from "../../Partners/Partners";
const UsedCarInsurance = (props) => {
    const { firstLot, secondLot } = props;

    let reasonsForCarIns = [
        {
            icon: FinancialProtection,
            title: "Financial Protection",
            description:
                "It provides financial security by covering the cost of repairs or replacement if your used car is damaged in an accident or stolen.",
        },
        {
            icon: LiabilityCoverage,
            title: "Liability Coverage",
            description:
                "It offers liability coverage, protecting you from financial responsibility if you cause an accident that injures others or damages their property.",
        },
        {
            icon: ProtectionDrivers,
            title: "Protection from Uninsured Drivers",
            description:
                "It safeguards you from uninsured or underinsured drivers who may cause accidents.",
        },
        {
            icon: LegalRequirement,
            title: "Legal Requirement",
            description:
                "In many places, having car insurance, even for used vehicles, is a legal requirement. Driving without insurance can lead to fines and legal consequences.",
        },

        {
            icon: peace,
            title: "Enhanced Peace of Mind",
            description:
                "Maintaining insurance on a used car can help preserve its resale value by ensuring that it's well-maintained and repaired when necessary.",
        },
        {
            icon: Resale,
            title: "Resale Value",
            description:
                "Used car insurance provides peace of mind, knowing that unexpected events won't lead to significant financial burdens.",
        },
    ];

    let planCards = [
        {
            id: 1,
            img: tata,
        },
        {
            id: 2,
            img: digit,
        },
        {
            id: 3,
            img: icici,
        },
        {
            id: 4,
            img: hdfc,
        },
    ];

    let timelineElements = [
        {
            id: 1,
            icon: assesments,
            title: "Research and Compare",
            description:
                "Research insurers, gather information, and compare quotes from different companies.",
        },
        {
            id: 2,
            icon: comparison,
            title: "Choose Coverage Limits",
            description:
                "Decide on the coverage limits for injuries and property damage.",
        },
        {
            id: 3,
            icon: carDetails,
            title: "Enter Car Details",
            description:
                "Provide your car's registration number and other essential information.",
        },
        {
            id: 4,
            icon: rightPolicy,
            title: "Make Online Payment",
            description:
                "Make your premium payment securely online using various methods, including debit/credit cards, internet banking, and digital wallets.",
        },
    ];

    let checklist = [
        "Personal information (age, gender).",
        "Driving history (accidents, violations).",
        "Vehicle details (make, model).",
        "Usage (frequency, purpose).",
        "Location (ZIP code).",
        "Coverage choices.",
        "Eligible discounts.",
    ];
    
    return (
        <>
            <Navbar />

            {/* HERO */}

            <div className="hero-section-product hero-section-product-tp-useCar">
                <img
                    src={car}
                    className="product-ins-hero-img car-product"
                    alt=""
                    srcset=""
                />
                <div className="hero-section-product-parent maxWidth">
                    <div className="hero-content-product-ins">
                        <h1 className="title-product-ins">
                            Used <span> Car Insurance</span>
                        </h1>
                        <p>
                            Used car insurance is coverage for vehicles that have previous owners and are not brand new.
                            It provides protection against accidents, theft, damage, and liability.
                            Premiums are typically lower than for new cars due to depreciation.
                            When selecting coverage, consider the car's age, mileage, and condition to find the right policy.
                        </p>
                    </div>
                </div>
            </div>

            {/* WHY CAR INSURANCE */}

            <div className="content-container-prod-insurance">
                <div className="content-container-prod-insurance-parent maxWidth">
                    <h1 className="title-product-ins">
                        Why Used Car Insurance <span>is Important ?</span>
                    </h1>
                    <div className="list-container-prod-ins">
                        <div className="list-container-prod-left list-container-prod-block">
                            {reasonsForCarIns.map(
                                (data, key) =>
                                    key < 3 && (
                                        <div key={key} className="list-rectangle">
                                            <div className="list-rectangle-icon-square">
                                                <img src={data.icon} alt="" srcset="" />
                                            </div>
                                            <div className="list-rectangle-content">
                                                <p>{data.title}</p>
                                                <p>{data.description}</p>
                                            </div>
                                        </div>
                                    )
                            )}
                        </div>
                        <div className="list-container-prod-right list-container-prod-block">
                            {reasonsForCarIns.map(
                                (data, key) =>
                                    key >= 3 && (
                                        <div key={key} className="list-rectangle">
                                            <div className="list-rectangle-icon-square">
                                                <img src={data.icon} alt="" srcset="" />
                                            </div>
                                            <div className="list-rectangle-content">
                                                <p>{data.title}</p>
                                                <p>{data.description}</p>
                                            </div>
                                        </div>
                                    )
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {/* WHY to buy from us */}

            <div className="content-container-prod-insurance  explanation-container-prod-insurance  ">
                <div className="content-container-prod-insurance-parent maxWidth">
                    <div className="mobile-container-with-content">
                        <div className="mob-container-prod-pg">
                            <div className="circle-gradient-prod-pg">
                                <img src={mobile} alt="" srcset="" />
                            </div>
                        </div>
                        <div className="content-container-prod-pg">
                            <div className="hero-content-product-ins w2b-content-product-ins">
                                <h1 className="title-product-ins">
                                    Why buy <span> from us? </span>
                                </h1>
                                <p>
                                    When you choose to buy insurance from us, you're selecting a
                                    trusted partner dedicated to safeguarding what matters most to
                                    you.
                                </p>
                                <p>
                                    Our competitive pricing, unwavering financial stability, and
                                    exceptional customer service make us the reliable choice for
                                    your insurance needs. With a reputation built on trust and a
                                    commitment to your peace of mind, we're here to provide the
                                    security and support you deserve. Choose us for insurance that
                                    truly cares about your well-being.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="card_div_container  card_div_container-prod-pg">
                        <div className="card_container_child">
                            <Card
                                icons={claim}
                                className="card_landing"
                                classNameTwo="cardLanding_content"
                                classNameThree="icon_container_landing"
                                title="100% Claims"
                                subtitle="Claim Guarantee"
                                hoverContent="in insurance ensures that the policyholder will receive full compensation for covered losses without any deductions or exceptions."
                            />
                            <Card
                                icons={emi}
                                className="card_landing"
                                classNameTwo="cardLanding_content"
                                classNameThree="icon_container_landing"
                                title="EMI Available"
                                subtitle="EMI option available on Credit Cards"
                            />
                            <Card
                                icons={cashless}
                                className="card_landing"
                                classNameTwo="cardLanding_content"
                                classNameThree="icon_container_landing"
                                title="100% Cashless"
                                subtitle="Cashless Policies"
                            />
                            <Card
                                icons={assistance}
                                className="card_landing"
                                classNameTwo="cardLanding_content"
                                classNameThree="icon_container_landing"
                                title="Claim Assistance"
                                subtitle="Free Claim assistance for all Insurance"
                            />
                        </div>
                        <div className="card_container_child">
                            <Card
                                icons={policy}
                                className="card_landing"
                                classNameTwo="cardLanding_content"
                                classNameThree="icon_container_landing"
                                title="Policy Correction"
                                subtitle="Free Online Policy Correction"
                            />
                            <Card
                                icons={refund}
                                className="card_landing"
                                classNameTwo="cardLanding_content"
                                classNameThree="icon_container_landing"
                                title="100% Refund"
                                subtitle="Free Policy Cancellation 100% Refund"
                            />
                            <Card
                                icons={tracking}
                                className="card_landing"
                                classNameTwo="cardLanding_content"
                                classNameThree="icon_container_landing"
                                title="Live Tracking"
                                subtitle="Free Claims Live Tracking"
                            />
                            <Card
                                icons={standalone}
                                className="card_landing"
                                classNameTwo="cardLanding_content"
                                classNameThree="icon_container_landing"
                                title="Stand Alone"
                                subtitle="Stand Alone Products"
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* How to Transfer Ownership */}

            <div className="content-container-prod-insurance comparison-container-prod-insurance">
                <div className="content-container-prod-insurance-parent maxWidth">
                    <div className="Owner-ship-txt">
                        <h1 className="title-product-ins">
                            How to Transfer Ownership and Insurance of
                            <span> Used Car </span>
                        </h1>
                        <p>
                            <div>
                                When acquiring a used car, the initial step is to transfer ownership into your name.Likewise, a<br />
                                pre-owned vehicle may come with an existing insurance policy, which might be valid for a <br />
                                certain period.Therefore, it is imperative to execute both the vehicle ownership transfer and<br />
                                insurance transfer.Here are the steps to facilitate these processes.
                            </div>
                        </p>
                    </div>

                    <div className='table-container-prod-pg-usedcar'>
                        <div className="points-comp-ins">
                            <div className="success-icon">
                                <img src={success} alt="" srcset="" />
                            </div>
                            <p className="point-title-comp-ins">
                                <span>
                                    Go to your local RTO (Regional Transport Office) and file an application for the transfer of vehicle ownership.
                                </span>
                            </p>
                        </div>

                        <div className="points-comp-ins">
                            <div className="success-icon">
                                <img src={success} alt="" srcset="" />
                            </div>
                            <p className="point-title-comp-ins">
                                <span>
                                    Provide all the necessary documentation and complete the fee payments.
                                </span>
                            </p>
                        </div>

                        <div className="points-comp-ins">
                            <div className="success-icon">
                                <img src={success} alt="" srcset="" />
                            </div>
                            <p className="point-title-comp-ins">
                                <span>
                                    After completing the RC transfer, you can get in touch with the insurance provider to initiate the insurance transfer.
                                </span>
                            </p>
                        </div>

                        <div className="points-comp-ins">
                            <div className="success-icon">
                                <img src={success} alt="" srcset="" />
                            </div>
                            <p className="point-title-comp-ins">
                                <span>
                                    Provide the updated proposal form along with the required documents.
                                </span>
                            </p>
                        </div>

                        <div className="points-comp-ins">
                            <div className="success-icon">
                                <img src={success} alt="" srcset="" />
                            </div>
                            <p className="point-title-comp-ins">
                                <span>
                                    Complete the payment of the insurance transfer fees, and the insurance company will then transfer the policy into your name.
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Top Car Insurance Plans */}
            <div className="content-container-prod-insurance">
                <div className="content-container-prod-insurance-parent maxWidth">
                    <h1 className="title-product-ins">
                        Top Car Insurance Plans Available on <span>BookMyInsurance</span>
                    </h1>
                    <div className="cards-container-product-ins">
                        {planCards.map((data, key) => (
                            <div key={key} className="card-prod-ins">
                                <div className="ins-logo-container">
                                    <img src={data.img} alt="" srcset="" />
                                </div>
                                <div className="breakup_container breakup_container-dummy ">
                                    <div className="planHeadValueContainer">
                                        <div className="planHead">
                                            <p>
                                                Basic Price <span>₹ 11,222.41</span>
                                            </p>
                                        </div>
                                        <div className="result_pc">
                                            <img src={success} alt="" srcset="" />
                                        </div>
                                    </div>
                                    <div className="planHeadValueContainer">
                                        <div className="planHead">
                                            <p>Personal Accident Cover</p>
                                        </div>
                                        <div className="result_pc">
                                            <img src={success} alt="" srcset="" />
                                        </div>
                                    </div>
                                    <div className="planHeadValueContainer">
                                        <div className="planHead">
                                            <p>Zero Depreciation</p>
                                        </div>
                                        <div className="result_pc">
                                            <img src={success} alt="" srcset="" />
                                        </div>
                                    </div>
                                    <div className="planHeadValueContainer">
                                        <div className="planHead">
                                            <p>Engine Protector</p>
                                        </div>
                                        <div className="result_pc">
                                            <img src={success} alt="" srcset="" />
                                        </div>
                                    </div>
                                    <div className="planHeadValueContainer">
                                        <div className="planHead">
                                            <p>Consumable</p>
                                        </div>
                                        <div className="result_pc">
                                            <img src={success} alt="" srcset="" />
                                        </div>
                                    </div>
                                    <div className="planHeadValueContainer">
                                        <div className="planHead">
                                            <p>Key and Lock Replacement</p>
                                        </div>
                                        <div className="result_pc">
                                            <img src={fail} alt="" srcset="" />
                                        </div>
                                    </div>
                                    <div className="planHeadValueContainer">
                                        <div className="planHead">
                                            <p>Invoice Cover</p>
                                        </div>
                                        <div className="result_pc">
                                            <img src={success} alt="" srcset="" />
                                        </div>
                                    </div>
                                    <div className="planHeadValueContainer">
                                        <div className="planHead">
                                            <p>24x7 Roadside Assistance</p>
                                        </div>
                                        <div className="result_pc">
                                            <img src={success} alt="" srcset="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="bottom-card-btn">
                                    <p>Get a Quote</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* HOW TO BUY CAR INSURANCE */}

            <div className="content-container-prod-insurance comparison-container-prod-insurance">
                <div className="content-container-prod-insurance-parent maxWidth">
                    <h1 className="title-product-ins">
                        How to buy Zero Depreciation Car Insurance <span>from us</span>
                    </h1>

                    <div className="timeline-container-and-lady">
                        <div className="lady-container-prod-pg">
                            <div className="lady-box-prod-pg">
                                <img src={lady} alt="" srcset="" />
                            </div>
                        </div>
                        <div className="timeline-container-prod-pg">
                            <Timeline timelineElements={timelineElements} />
                        </div>
                    </div>
                </div>
            </div>

            {/* CALCULATE PREMIUM  */}

            <div className="content-container-prod-insurance prem-calc-container-prod-insurance">
                <div className="content-container-prod-insurance-parent maxWidth">
                    <div className="mobile-container-with-content mobile-container-with-content-two ">
                        <div className="content-container-prod-pg content-container-prod-pg-two ">
                            <div className="hero-content-product-ins w2b-content-product-ins">
                                <h1 className="title-product-ins">
                                    How to calculate <span> Car Insurance Premium </span>
                                </h1>
                                <p className="bold-description-calc">
                                    Car insurance companies use online calculators to estimate
                                    premium amounts based on various factors.
                                </p>
                                <p>
                                    You provide personal details, driving history, vehicle
                                    information, usage patterns, and location. <br />
                                    Then, you choose coverage options and input applicable
                                    discounts. The calculator processes this data through an
                                    algorithm to generate an estimated premium quote. <br />
                                    It's essential to remember that this quote serves as an
                                    estimate, and the final premium may vary depending on further
                                    underwriting and insurer-specific factors. Comparing quotes
                                    from multiple insurers helps you find the most suitable and
                                    cost-effective coverage.
                                </p>
                                <p className="bold-description-calc">
                                    Car insurance premiums are estimated using online calculators,
                                    considering:
                                </p>
                                <div className="checklist-calc-container-parent">
                                    {checklist.map((text, key) => (
                                        <div key={key} className="checklist-calc-container">
                                            <img src={success} alt="" srcset="" />
                                            <p>{text}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="mob-container-prod-pg">
                            <div className="calc-gradient-prod-pg">
                                <img src={calculator} alt="" srcset="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* LIST- COVERED & NOT_COVERED */}

            <div className="content-container-prod-insurance comparison-container-prod-insurance">
                <div className="content-container-prod-insurance-parent covered-container-prod-insurance-parent maxWidth">
                    <div className="covered-container-compinspolicy">
                        <h1 className="title-product-ins">
                            What is Covered <span> in Used Car Insurance</span>
                        </h1>
                        <div className="covered-rect-comp-ins">
                            <div className="covered-point-comp-ins">
                                <div className="p-tag-container-compins">
                                    <div className="points-comp-ins">
                                        <div className="success-icon">
                                            <img src={success} alt="" srcset="" />
                                        </div>
                                        <p className="point-title-comp-ins">
                                            Accidents and Collisions:&nbsp;
                                            <span>
                                                Used car insurance typically covers damage caused by accidents, collisions, and vandalism.
                                            </span>
                                        </p>
                                    </div>
                                    <div className="points-comp-ins">
                                        <div className="success-icon">
                                            <img src={success} alt="" srcset="" />
                                        </div>
                                        <p className="point-title-comp-ins">
                                            Liability: &nbsp;
                                            <span>
                                                It provides liability coverage, which pays for injuries or property damage you may cause to others in an accident.
                                            </span>
                                        </p>
                                    </div>
                                    <div className="points-comp-ins">
                                        <div className="success-icon">
                                            <img src={success} alt="" srcset="" />
                                        </div>
                                        <p className="point-title-comp-ins">
                                            Theft and Fire:&nbsp;
                                            <span>
                                                Used car insurance usually covers theft, burglary, and damage due to fire or natural disasters.
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="covered-container-compinspolicy">
                        <h1 className="title-product-ins">
                            What is Not covered {" "}
                            <span>in Used Car Insurance</span>
                        </h1>
                        <div className="covered-rect-comp-ins">
                            <div className="covered-point-comp-ins">
                                <div className="p-tag-container-compins">
                                    <div className="points-comp-ins">
                                        <div className="success-icon">
                                            <img src={success} alt="" srcset="" />
                                        </div>
                                        <p className="point-title-comp-ins">
                                            Regular Wear and Tear:&nbsp;
                                            <span>
                                                Expenses related to the natural wear and tear of car
                                                parts due to age and usage are not covered..
                                            </span>
                                        </p>
                                    </div>
                                    <div className="points-comp-ins">
                                        <div className="success-icon">
                                            <img src={success} alt="" srcset="" />
                                        </div>
                                        <p className="point-title-comp-ins">
                                            Mechanical Failures: &nbsp;
                                            <span>
                                                Repairs or replacements due to mechanical failures unrelated to accidents or covered events are often excluded.
                                            </span>
                                        </p>
                                    </div>
                                    <div className="points-comp-ins">
                                        <div className="success-icon">
                                            <img src={success} alt="" srcset="" />
                                        </div>
                                        <p className="point-title-comp-ins">
                                            Aftermarket Modifications:&nbsp;
                                            <span>
                                                Modifications and accessories added to the car after purchase may not be covered unless specified in the policy.
                                            </span>
                                        </p>j
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* partners section */}

            <div className="content-container-prod-insurance ">
                <div className="content-container-prod-insurance-parent maxWidth">
                    <h1 className="title-product-ins">
                        Top Car Insurance Companies in <span> India</span>
                    </h1>
                    <div className="partner-container-prod-pg">
                        <Partners
                            firstLot={firstLot}
                            secondLot={secondLot}
                            hideTitle={true}
                            noPadding="noPadding"
                            component="thirdPartyIns"
                        />
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default UsedCarInsurance
