import React from "react";
import back from "../../Assets/Images/backIcon.svg";
import InputFields from "../InputFields/InputFields";
import FormContainer from "./formContainer";
import Tab from "../Card/Tab";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import gradOne from "../../Assets/Images/tabGradOne.svg"
import tabOne from "../../Assets/Images/tabOne.svg"

const MobileSelectedProduct = (props) => {
    const {
        carIcon,
        bikeIcon,
        ShowProduct,
        setIsMobileMode,
        stateForMobileContent,
        setShowCarContent,
        productValue,
        ShowProductMobile,
        ProductTabClicked,
        handleVehicleNumber,
        vehicleNumber,
        irdai,
        irdaiContentOne,
        irdaiContentTwo,
        mobileCLickEvent,
    } = props;

    let tabContent = [
        {
            content: "Why is a process  1  .",
            boldContent: "Why is a process.",
            boldContentTwo: "Why is a process.",
        }, {
            content: "Why is a process  2  .",
            boldContent: "Why is a process.",
            boldContentTwo: "Why is a process.",
        }, {
            content: "Why is a process  3  .",
            boldContent: "Why is a process.",
            boldContentTwo: "Why is a process.",
        }, {
            content: "Why is a process  4  .",
            boldContent: "Why is a process.",
            boldContentTwo: "Why is a process.",
        },
    ]

    return (
        <>
            <div className="mobile-product-container">
                <div className="back-mob-prod" onClick={(e) => setIsMobileMode(false)} >
                    <img src={back} /> <p>Back to home</p>
                </div>

                <div className="mobile-prod-btn">
                    <img
                        src={ShowProduct === "car" ? carIcon : bikeIcon}
                    />
                    <div>
                        <span>{ShowProduct === "car" ? "Car" : "Bike"}</span> Insurance
                    </div>
                </div>

                <FormContainer
                    stateForMobileContent={stateForMobileContent}
                    setShowCarContent={setShowCarContent}
                    productValue={productValue}
                    ShowProductMobile={ShowProductMobile}
                    ProductTabClicked={ProductTabClicked}
                    handleVehicleNumber={handleVehicleNumber}
                    vehicleNumber={vehicleNumber}
                    irdai={irdai}
                    irdaiContentOne={irdaiContentOne}
                    irdaiContentTwo={irdaiContentTwo}
                    mobileCLickEvent={mobileCLickEvent}
                    ShowProduct={ShowProduct}
                />

                <p>Recommended for you</p>

                <Tab
                    content={<><p className="tab-content"> Why <span>Claim Settlement</span> is a <span>slow and painful</span> process. </p></>}
                    gradImg={gradOne}
                    img={tabOne}
                />

                {/* <div className="swiper-mob-container">
                    {
                        tabContent?.map((data, key) => (
                            <div key={key} className="">
                                <Tab
                                    content={data.content}
                                    boldContent={data.boldContent}
                                    boldContentTwo={data.boldContentTwo}
                                />
                            </div>
                        ))
                    }
                </div> */}

                {/* <Swiper
                    spaceBetween={50}
                    slidesPerView={1}
                    navigation
                    pagination={{ clickable: true }}
                    autoplay={{ delay: 3000 }} // 3 seconds
                    loop={true}
                >
                    {tabContent?.map((data, index) => (
                        <SwiperSlide key={index}>
                            <Tab
                                content={data.content}
                                boldContent={data.boldContent}
                                boldContentTwo={data.boldContentTwo}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper> */}

                {/* <Swiper
                    spaceBetween={30}
                    centeredSlides={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={true}
                    modules={[Autoplay, Pagination]}
                    className="mySwiper"
                >
                    <div className="swiper-containner">
                        {
                            tabContent?.map((data, key) => (
                                <SwiperSlide className="SwiperSlide-container">
                                    <div key={key} className="swiper-mob-container">
                                        <Tab
                                            content={data.content}
                                            boldContent={data.boldContent}
                                            boldContentTwo={data.boldContentTwo}
                                        />
                                    </div>
                                </SwiperSlide>
                            ))
                        }
                    </div>
                </Swiper> */}

            </div>
        </>
    );
};

export default MobileSelectedProduct;
